.cards {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 30px 20px;
  background: #fff;
  color: #7f7f7f;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.08);
  transition: transform 100ms ease;
  font-size: 0.875em;
  z-index: 1;
}
.card.isClickable {
  cursor: pointer;
  user-select: none;
}
@media (min-width: 768px) {
  .card.isClickable:hover {
    transform: scale(1.05);
    z-index: 10;
  }
}
.card.withChevron {
  padding-right: 40px;
}
.card.withChevron:after {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 16px;
  height: 16px;
  border-right: solid 1px #666;
  border-bottom: solid 1px #666;
  background: transparent;
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
}
.card + .card {
  margin-top: 10px;
}
.card a > strong,
.card div > strong,
.card > div h3 {
  color: black;
}

.cardIcon {
  margin-right: 15px;
}

.cardContent {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #7f7f7f;
}
.cardContent > svg {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.info {
  display: flex;
  align-items: center;
}
.info .icon {
  margin-right: 0.75rem;
}
.info .content {
  display: flex;
  flex-flow: column;
}

.cell {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}
.cell + .cell {
  justify-content: center;
}
.cell:last-child {
  flex: content;
  justify-content: flex-end;
}
.cell > svg {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}