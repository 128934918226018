.card,
.dates {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info {
  margin-left: 15px;
  flex: 1;
}

.subtext {
  margin: 5px 0;
  font-weight: normal;
}
.subtext:first-child {
  margin-top: 0;
}

.dates {
  font-size: 0.875em;
}
.dates > time {
  color: inherit;
  opacity: 1;
  font-size: 1em;
}
.dates > time:first-child {
  margin-right: 5px;
}
.dates > time:last-child {
  margin-left: 5px;
}