.welcome,
.contents {
  width: 94%;
  max-width: 640px;
  margin: 0 auto;
}

.welcome {
  padding-top: 30px;
  padding-bottom: 50px;
}
.welcome > h1 {
  font-size: 32px;
  font-weight: bold;
}

.contents {
  padding-bottom: 100px;
}
.contents > h3 {
  margin: 50px 0 10px;
}
.contents > hr {
  margin: 50px 0;
}

.pastTicketsLink {
  margin-top: 30px;
}