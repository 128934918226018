.list {
  position: relative;
  margin: 50px 0;
}

.node {
  position: relative;
  padding: 0 0 30px 50px;
  opacity: 0.5;
}
.node svg {
  position: absolute;
  left: 2px;
  width: 14px;
  height: 14px;
  z-index: 5;
  top: 2px;
}
.node:before, .node:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.node:before {
  left: 9px;
  bottom: 0;
  border-left: dotted 1px currentColor;
  z-index: 1;
}
.node:after {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: solid 1px currentColor;
  background: #fff;
  z-index: 2;
}
.node:last-child:before {
  display: none;
}
.node.isCompleted + .node {
  opacity: 1;
}
.node.isCompleted + .node:after {
  border-color: transparent;
  background: #da5d39;
}
.node.isCompleted {
  opacity: 1;
}
.node.isCompleted:before {
  border-color: #da5d39;
}
.node.isCompleted:after {
  background: #fff !important;
  border-color: #da5d39 !important;
  content: "";
}

.label {
  position: relative;
}

.content {
  padding: 6px 0 0 0;
  font-size: 0.875em;
  opacity: 0.7;
}
.content time {
  font: inherit;
  color: inherit;
  opacity: 1;
}