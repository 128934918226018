@media screen and (min-width: 820px) {
  a.avatar,
.avatar.isClickable {
    transition: transform 250ms ease;
  }
  a.avatar:hover,
.avatar.isClickable:hover {
    transform: scale(1.1);
  }
}
@media (prefers-reduced-motion) {
  a.avatar,
.avatar.isClickable {
    transition: none !important;
  }
}

.avatar {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: medium;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  user-select: none;
  border-radius: 50%;
  width: 3em;
  align-self: center;
}
.avatar > img {
  width: 100%;
  height: 100%;
}
.avatar:before {
  display: block;
  padding-bottom: 100%;
  content: "";
}

a.avatar,
.avatar.isClickable {
  cursor: pointer;
}