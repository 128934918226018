.content,
.formContent {
  width: 94%;
  max-width: 640px;
  margin: 0 auto;
}

.content {
  width: 100vw;
  padding: 50px 0 200px;
}

.comment {
  padding: 20px 45px 20px 3%;
  margin: 2px 0;
  position: relative;
}
.comment > header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.comment > section {
  padding-left: 40px;
}

.comment.isInternal {
  background-color: #FFECDB;
}

.actionWrap {
  width: 30px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.author {
  margin: 0 15px 0 10px;
  font-weight: bold;
}

.form {
  position: fixed;
  padding: 10px 20px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 40;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
}

.formContent {
  position: relative;
}

.modeControls {
  position: absolute;
  right: 0;
  top: 13px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #9e9e9e;
  z-index: 5;
}

.modeControls > div {
  cursor: pointer;
  position: relative;
}

.modeControls .externalControl {
  color: #DA5D39;
  margin-right: 16px;
}

.modeControls > div:after {
  content: "";
  position: absolute;
  bottom: -5px;
  display: block;
  width: 100%;
  height: 0;
  border-bottom: 2px solid #DA5D39;
  opacity: 0;
}

.modeControls .externalControl:after {
  opacity: 1;
}

.isInternal .modeControls .externalControl {
  color: #9e9e9e;
}

.isInternal .modeControls .externalControl:after {
  opacity: 0;
}

.isInternal .modeControls .internalControl {
  color: #DA5D39;
}

.isInternal .modeControls .internalControl:after {
  opacity: 1;
}

.isInternal textarea {
  background-color: #ffecdb;
}

.commentDetails {
  display: flex;
  flex-wrap: wrap;
}

.commentDetails .author {
  margin-right: 0;
}

.commentDetails time {
  margin-left: 10px;
}

.ticketCommentBody {
  line-height: 22px;
}

.ticketViewLink, .ticketViewLinkEmpty {
  font-size: 0.875em;
  font-weight: bold;
}

.ticketViewLink {
  margin-left: 0.875em;
}

.commentEditingDrawer {
  position: relative;
  height: 100%;
  padding: 10px;
}

.commentEditingDrawer textarea {
  height: 175px;
}

.commentEditingButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.commentEditingButtons button {
  width: 49%;
}

.noComments {
  margin: 0 auto;
}

@media (max-width: 720px) {
  .noComments {
    width: 94%;
    max-width: 640px;
    padding-left: 20px;
  }
}