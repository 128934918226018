.hint {
  margin: 20px 0;
  padding: 20px;
  border: dashed 2px currentColor;
}
.hint > h1,
.hint > h2,
.hint > h3,
.hint > h4,
.hint > h5,
.hint > h6,
.hint > p,
.hint > ul,
.hint > ol,
.hint > button {
  margin-bottom: 20px;
}
.hint > h1:last-child,
.hint > h2:last-child,
.hint > h3:last-child,
.hint > h4:last-child,
.hint > h5:last-child,
.hint > h6:last-child,
.hint > p:last-child,
.hint > ul:last-child,
.hint > ol:last-child,
.hint > button:last-child {
  margin-bottom: 0;
}
.hint > ul {
  list-style: inside disc;
}
.hint > ul > li {
  margin: 5px 0;
}
.hint.warning {
  border: solid 2px #ff9317;
  background: #ffe8be;
}