.items {
  width: 94%;
  max-width: 640px;
  margin: 0 auto;
}

.items {
  padding-bottom: 100px;
}
.items hr {
  margin: 30px 0;
  border: 0;
  border-top: solid #ccc 1px;
}