.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 3em;
  font: inherit;
  font-weight: bold;
  user-select: none;
  overflow: hidden;
  color: #fff;
  background-color: #131313;
  text-transform: uppercase;
  min-height: 44px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.5px;
  letter-spacing: 0.08em;
}
.button.bordered {
  background-color: #fff;
  border: solid 2px #131313;
  color: #131313;
}
.button.danger {
  background-color: #FF5C5D;
  color: #fff;
}
.button.danger.bordered {
  border: solid 2px #FF5C5D;
  background-color: #Ffff;
  color: #FF5C5D;
}
.button:focus:before, .button:hover:before {
  opacity: 1;
}
.button:disabled {
  color: #717171;
  background-color: #C0C0C0;
  border-color: #B7B7B7;
}
.button.isFullWidth {
  width: 100%;
}
.button.requestReset {
  margin-top: 30px;
}
@media (max-width: 768px) {
  .button {
    padding: 0 1em;
  }
}

.label {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  text-align: center;
}
.label > svg:first-child {
  margin-right: 0.5rem;
}