.root {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  max-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 65px;
  height: 65px;
}

.heading {
  font-size: 36px;
  line-height: 44px;
  margin-top: 15px;
}

.subHeading {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 8%;
  margin-top: 5px;
}

.instruction {
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
}