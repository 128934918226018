.root {
  position: fixed;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
  z-index: 40;
}
.root > button + button {
  margin-left: 10px;
}
@media (max-width: 768px) {
  .root > button {
    flex: 1;
  }
  .root.isStacked {
    flex-direction: column;
  }
  .root.isStacked > button + button {
    margin-left: 0;
    margin-top: 10px;
  }
}