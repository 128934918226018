.instructions, .smallInstructions {
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
}

.smallInstructions {
  font-size: 12px;
}

.form {
  width: 100%;
  margin-top: 15px;
}
.form > button {
  margin-top: 40px;
}
.form > a {
  display: block;
  margin-top: 25px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-decoration: underline;
  color: #242525;
}

.spacer {
  height: 50px;
}