.content {
  width: 94%;
  max-width: 640px;
  margin: 0 auto;
}

.content {
  padding: 32px 0;
}

.stickyTabs {
  position: sticky;
  top: 60px;
  z-index: 30;
}

.header {
  background: #fff;
}

.banner {
  background: url("../../img/TechBanner.png") no-repeat center center;
  background-size: cover;
  height: 140px;
}

.techInfo {
  position: relative;
  top: -32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.techInfo > h1 {
  margin: 10px 0;
}