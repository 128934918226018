.root {
  padding-top: 60px;
  background: #fafafa;
  min-height: 100vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  height: 60px;
  padding: 0 20px 0 0;
  background: #fff;
}
@media (min-width: 768px) {
  .navbar {
    padding: 0 40px;
  }
}

.trigger,
.logo {
  cursor: pointer;
}

.logo {
  width: 32px;
  height: 32px;
}

.trigger {
  display: block;
  position: relative;
  width: 60px;
  height: 60px;
}
.trigger > span {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.trigger > span, .trigger > span:before, .trigger > span:after {
  position: absolute;
  width: 26px;
  height: 3px;
  background: #333;
  border-radius: 3px;
  content: "";
}
.trigger > span:before {
  top: -8px;
  left: 0;
  transition: transform 150ms ease;
  transform: translateY(0px);
}
.trigger > span:after {
  bottom: -8px;
  left: 0;
  transition: transform 150ms ease;
  transform: translateY(0px);
}
.trigger:hover > span:before {
  transform: translateY(-3px);
}
.trigger:hover > span:after {
  transform: translateY(3px);
}

.drawerHeader {
  padding: 60px 20px 20px;
  border-bottom: solid 1px #ccc;
}
.drawerHeader > strong {
  display: block;
}

.drawerFooter {
  border-top: solid 1px #ccc;
}