.eventName {
  margin-right: 20px;
}
.eventName + .eventSubtext {
  opacity: 0.7;
}

.eventDates {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.875em;
}
.eventDates > time {
  color: inherit;
  opacity: 1;
  font-size: 1em;
}
.eventDates > time:first-child {
  margin-right: 5px;
}
.eventDates > time:last-child {
  margin-left: 5px;
}