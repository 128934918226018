.content {
  width: 94%;
  max-width: 640px;
  margin: 0 auto;
}

.action, .title, .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.root {
  position: sticky;
  top: 60px;
  z-index: 45;
  background: #fff;
  border-bottom: solid 1px #eee;
  padding: 20px;
  width: auto;
}
.root > a {
  cursor: pointer;
  user-select: none;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .content {
    display: block;
  }
}

.row {
  width: 100%;
  justify-content: space-between;
}
.row + .row {
  margin-top: 10px;
}

.title {
  color: inherit;
}
.title > .icon {
  position: relative;
  margin-right: 10px;
}

.info {
  display: block;
}
.info > h1 {
  font: inherit;
  font-weight: bold;
}
.info > h2 {
  font: inherit;
  font-size: 0.875em;
  opacity: 0.5;
}

.action {
  position: relative;
  min-width: 20px;
  background: transparent;
  cursor: pointer;
  user-select: none;
  color: inherit;
  text-decoration: underline;
}
.action:disabled {
  opacity: 0.4;
}