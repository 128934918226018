.pages,
.pageNumbers {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages {
  margin-top: 25px;
  margin-bottom: 10px;
}

.pageNumbers {
  flex: 1;
}

.pageNumber {
  padding: 10px;
  opacity: 0.7;
  transition: opacity 250ms ease;
  cursor: pointer;
}
.pageNumber:hover, .pageNumber.isCurrent {
  opacity: 1;
}
.pageNumber.isCurrent {
  font-weight: bold;
}

.prevPageButton,
.nextPageButton {
  position: relative;
  width: 50px;
  background: transparent;
  cursor: pointer;
}
.prevPageButton:disabled,
.nextPageButton:disabled {
  opacity: 0.4;
}
.prevPageButton:before,
.nextPageButton:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  background: transparent;
  border-right: solid 1px #666;
  border-bottom: solid 1px #666;
  content: "";
}

.prevPageButton:before {
  transform: translate(-50%, -50%) rotate(135deg);
}

.nextPageButton:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.perPageSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.perPageControl {
  position: relative;
  margin-left: 10px;
}
.perPageControl > select {
  padding: 0 30px 0 10px;
  width: auto;
}

.selectArrow {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 12px;
  height: 12px;
  border-right: solid 1px #666;
  border-bottom: solid 1px #666;
  background: transparent;
  transform: translate(-50%, -75%) rotate(45deg);
  content: "";
  user-select: none;
  pointer-events: none;
}