.toasts {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  z-index: 70;
}

.toast {
  display: flex;
  position: relative;
  padding: 1rem 1.5rem 1rem 1rem;
  background: #717171;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 0.125rem;
}
.toast .icon {
  margin-right: 0.5rem;
}

.close {
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
}
.close > svg {
  width: 14px;
  height: 14px;
}