.items {
  width: 94%;
  max-width: 640px;
  margin: 0 auto;
}

.info > h3,
.info > h3,
.items > h3,
.items > h3 {
  margin: 50px 0 10px;
}
.info > h3:first-child,
.info > h3:first-child,
.items > h3:first-child,
.items > h3:first-child {
  margin-top: 0;
}
.info > hr,
.items > hr {
  margin: 50px 0;
}

.items {
  padding: 30px 0;
}