.button {
  background: #ff5c5d;
  color: #fff;
  padding: 0;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  transition: transform 250ms ease;
  height: 36px;
  width: 80px;
}
.button:hover {
  transform: scale(1.25);
}
.button:disabled {
  background: #999;
}