.items {
  width: 94%;
  max-width: 640px;
  margin: 0 auto;
}

.items {
  padding: 25px 0 100px;
}

.search {
  position: relative;
  margin-right: 10px;
  flex: 1;
}
.search > input[type=text] {
  padding-right: 50px;
  z-index: 1;
}
.search > svg {
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 0.5;
  transform: translate(-50%, -50%);
  z-index: 10;
}