body.isBodyLocked {
  overflow: hidden;
}

.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 50;
}
.root > .drawer {
  top: 0;
  bottom: 0;
  right: 0;
  width: 300px;
  transform: translateX(100%);
}
.root.isLeftAligned > .drawer {
  left: 0;
  right: auto;
  transform: translateX(-100%);
}
.root.isOpen {
  pointer-events: all;
}
.root.isOpen > .overlay {
  opacity: 1;
}
.root.isOpen > .drawer {
  opacity: 1;
  transform: translateX(0%);
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);
}

.drawer {
  display: flex;
  flex-direction: column;
  position: absolute;
  transition: opacity 150ms ease, transform 300ms ease;
  background: #fff;
  z-index: 5;
  width: 90%;
  max-width: 300px;
}

.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 60px;
  height: 60px;
  z-index: 10;
}
.closeIcon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  opacity: 0;
  background: rgba(0, 0, 0, 0.08);
  transition: opacity 100ms ease;
  cursor: pointer;
}

.content {
  padding: 25px;
}

.scroll,
.root.isScrollable > .drawer {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.heading,
.button,
.toggleButton {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  color: inherit;
  user-select: none;
}

.heading:first-child {
  padding-right: 60px;
}
.heading:not(:first-child) {
  border-top: solid 1px #eee;
}
.heading:not(:last-child) {
  border-bottom: solid 1px #eee;
}

.button,
.toggleButton {
  cursor: pointer;
}
.button:before,
.toggleButton:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fafafa;
  content: "";
  z-index: 1;
  opacity: 0;
  transition: opacity 100ms ease;
}
.button.isActiveLink:before,
.toggleButton.isActiveLink:before {
  border-right: solid 2px #666;
}
.button.isActiveLink:before, .button.isActive:before, .button:hover:before,
.toggleButton.isActiveLink:before,
.toggleButton.isActive:before,
.toggleButton:hover:before {
  opacity: 1;
}

.button.isDanger {
  color: #FF5C5D;
}

.headingIcon,
.buttonIcon {
  position: relative;
  z-index: 3;
  width: 60px;
  height: 60px;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
}

.headingLabel,
.buttonLabel {
  position: relative;
  z-index: 3;
  flex: 1;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  white-space: nowrap;
}

.headingLabel {
  font-weight: bold;
}

.toggleButton > .buttonIcon {
  opacity: 0;
  transition: opacity 100ms ease, transform 150ms cubic-bezier(0.34, 1.56, 0.64, 1);
  transform: scale(0.5);
}
.toggleButton > .buttonLabel {
  opacity: 0.5;
  transition: opacity 100ms ease;
}
.toggleButton.isActive > .buttonIcon {
  opacity: 0.5;
  transform: scale(1);
}
.toggleButton.isActive > .buttonLabel {
  opacity: 1;
}