:root {
    --color-danger: #ff4136;
    --color-warning: #ff851b;
    --color-success: #2ecc40;
    --color-primary: #d35131;
    --color-secondary: #0074d9;
    --color-body-bg: #fff;
    --color-body-text: #111;
}


::selection {
    background: var(--color-primary);
    color: #fff;
}

html {
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
    font-family: "Raleway", Helvetica, sans-serif;
    background: var(--color-body-bg);
    color: var(--color-body-text);
}

h1,h2,h3,h4,h5,h6 {
    font-family: "Montserrat", Helvetica, sans-serif;
}

p,
ul,
ol {
    line-height: 1.5;
}

a {
    color: var(--color-primary);
    cursor: pointer;
}

img {
    max-width: 100%;
}

button,
input,
select,
textarea,
fieldset {
    font: inherit;
    margin: 0;
}

input,
select,
textarea {
    background: var(--color-body-bg);
    border: solid 1px #ccc;
    color: inherit;
    width: 100%;
    resize: none;

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }

    &[required] + label {
        &:after {
            color: var(--color-danger);
            content: "*";
        }
    }

    &:focus {
        color: var(--color-secondary);
        border-color: var(--color-secondary);

        + label {
            color: var(--color-secondary);
        }
    }
}

label {
    font-size: 0.875em;
    font-weight: bold;
    vertical-align: middle;
}

input[type="text"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
button {
    height: 2.5em;
    padding: 0 1em;
    vertical-align: middle;
    -webkit-appearance: none;
}

input[type="text"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"] {
    width: 100%;
}

select {
    // line-height: 1.75;
    padding: 0 1em;
    width: 100%;
    color: var(--color-body-text) !important;
    cursor: pointer;
}

select:not([multiple]) {
    height: 2.5em;
    vertical-align: middle;
}

textarea {
    // line-height: 1.75;
    line-height: 1.5;
    padding: 1em;
    width: 100%;
}

table {
    width: 100%;

    th,
    td {
        padding: 0.5em 0.75em;
        vertical-align: middle;
    }

    tr:nth-child(even) {
        background: rgba(0, 0, 0, 0.03);
    }
}

.hbox {
    display: flex;
    flex-direction: none;
    justify-content: space-between;
    align-items: center;
}

time {
    font-size: 0.875em;
    color: #999;
}